<template>
  <div class="register">
    <b-container id="form">
      <b-row>
        <b-col cols="12">
          <b-form @submit="onSubmit">
            <b-row class="form-row">
              <b-col>
                <b-form-group
                  id="input-first-name"
                  label="Vorname:"
                  label-for="input-first-name"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                  data-validation-text="Bitte ausfüllen"
                >
                  <b-col>
                    <b-form-input
                      id="first-name"
                      size="sm"
                      v-model="form.first_name"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                      v-on:blur="suggest_mail()"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-last-name"
                  label="Nachname:"
                  label-for="input-last-name"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col>
                    <b-form-input
                      id="last-name"
                      size="sm"
                      v-model="form.last_name"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                      v-on:blur="suggest_mail()"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-project"
                  label="Name des Projekts/der Iniative (optional):"
                  label-for="input-project"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col>
                    <b-form-input
                      id="project"
                      size="sm"
                      v-model="form.project"
                      :disabled="form_disabled"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-url"
                  label="Nachweis (nur bei einem Projekt/ einer Iniative):"
                  label-for="input-url"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col>
                    <b-form-input
                      id="url"
                      size="sm"
                      v-model="form.url"
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-mail"
                  label="Gewünschte E-Mail-Adresse:"
                  label-for="input-mail"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-input
                        id="mail"
                        size="sm"
                        v-model="form.mail"
                        required
                        :disabled="form_disabled"
                        aria-describedby="mail-feedback"
                        :state="mailState"
                        trim
                        data-validation-text="Bitte füllen Sie dieses Feld aus"
                      ></b-form-input>
                      <span class="input-group-text form-control-sm" style="font-size: 0.875rem" id="basic-addon2"
                        >@muenster.de</span
                      >
                    </div>
                  </b-col>
                  <b-col>
                    <b-form-text id="mail-info" v-show="this.mailState != null && !this.mailState">Die gewünschte E-Mail-Adresse @muenster.de muss mindestens drei Zeichen lang sein und mindestens drei
Buchstaben enthalten</b-form-text>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-birthdate"
                  label="Geburtsdatum:"
                  label-for="input-birthdate"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-datepicker
                      id="birthdate"
                      size="sm"
                      show-decade-nav
                      v-model="form.birthdate"
                      placeholder="Bitte wählen Sie ein Datum aus"
                      :min="minDate"
                      :max="maxDate"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                    ></b-form-datepicker>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-street"
                  label="Straße:"
                  label-for="street"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      list="street-list"
                      size="sm"
                      id="street"
                      v-model="form.street"
                      v-on:input="update_streets()"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                    ></b-form-input>
                    <b-form-datalist
                      id="street-list"
                      :options="options.streets"
                    ></b-form-datalist>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-street_number"
                  label="Hausnummer:"
                  label-for="street_number"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      list="streetnumbers-list"
                      id="street_number"
                      size="sm"
                      v-model="form.street_number"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                    ></b-form-input>
                    <b-form-datalist
                      id="streetnumbers-list"
                      :options="options.street_numbers"
                    ></b-form-datalist>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-zipcode"
                  label="Postleitzahl:"
                  label-for="input-zipcode"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="zipcode"
                      size="sm"
                      v-model="form.zipcode"
                      required
                      :disabled="true"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-phone"
                  label="Telefon:"
                  label-for="input-phone"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="phone"
                      size="sm"
                      v-model="form.phone_number"
                      type="tel"
                      pattern="[0-9].+"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte geben Sie eine gültige Telefonnummer im folgenden Format: '02514920' ein"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-mail"
                  label="Passwort senden an (optional):"
                  label-for="input-mail"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-input
                        id="alternative_mail"
                        size="sm"
                        v-model="form.alternative_mail"
                        :disabled="form_disabled"
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col>
                    <b-col>
                    <b-form-text
                      id="send-hint"
                      >E-Mail, an die das Passwort gesendet werden soll. Alternativ erfolgt die Mitteilung per Post (längere Bearbeitungszeit).</b-form-text
                    >
                  </b-col>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-notes"
                  label="Anmerkungen (optional):"
                  label-for="input-notes"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-textarea
                        id="notes"
                        size="sm"
                        v-model="form.notes"
                        rows="8"
                        :disabled="form_disabled"
                      ></b-form-textarea>
                    </div>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="this.form.captcha_id != ''">
              <b-col>
                <img :src="get_image_captcha_url()" />
              </b-col>
            </b-row>
            <b-row v-if="this.form.captcha_id != ''">
              <b-col>
                <audio
                  id="audio_with_controls"
                  controls
                  :src="get_audio_captcha_url()"
                  type="audio/mp3"
                >
                  Ihr Browser kann dieses Tondokument nicht wiedergeben.<br />
                </audio>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <img src="" />
                <b-form-group
                  id="input-captcha"
                  label="Captcha:"
                  label-for="input-captcha"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="phone"
                      size="sm"
                      v-model="form.captcha_solution"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte geben Sie die Zahlenfolge ein."
                      pattern="[0-9].+"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-checkbox
                  id="agreement"
                  v-model="form.agreement"
                  name="agreement"
                  required
                  :disabled="form_disabled"
                  data-validation-text="Bitte akzeptieren Sie die Nutzungsbedingungen."
                >
                  <b-col>
                    Ich akzeptiere die
                    <a
                      href="//www.muenster.de/email_nutzungsbedingungen.html"
                      target="_blank"
                      >Nutzungsbedingungen</a
                    >
                    für eine @muenster.de-Adresse. Außerdem bestätige ich, dass <b>die neue E-Mail Adresse in nachvollziehbaren Bezug zu meinem Namen bzw. dem Namen des Projekts/der Initiative steht</b>. Mir ist bewusst, dass der Zugang nicht angelegt wird und ich keine Rückmeldung erhalte, wenn dies nicht der Fall ist.
                  </b-col>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col>
                <b-alert v-model="msg_show" v-bind:variant="msg_type">
                  <p style="font-weight: bold;">{{ msg_text }}</p>
                </b-alert>
              </b-col>
            </b-row>
            <b-row v-if="!form_disabled">
              <b-col>
                <b-button
                  type="submit"
                  size="sm"
                  variant="primary"
                  :disabled="send_disabled"
                  >Antrag abschicken</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Register",
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    minDate.setYear(minDate.getFullYear() - 120);
    const maxDate = new Date(today);

    return {
      form: {
        first_name: "",
        last_name: "",
        birthdate: "",
        street: "",
        street_number: "",
        zipcode: "",
        phone_number: "",
        alternative_mail: "",
        mail: "",
        notes: "",
        project: "",
        url: "",
        captcha_id: "",
        captcha_solution: "",
        agreement: false,
      },
      options: {
        streets: ["Bitte ersten Buchstaben eintippen"],
        street_numbers: ["Bitte erst Straße auswählen"],
      },
      street_prefix: "",
      msg_text: "",
      msg_show: false,
      msg_type: "",
      form_disabled: false,
      send_disabled: true,
      form_send: false,
      minDate: minDate.toISOString(),
      maxDate: maxDate.toISOString(),
    };
  },
  mounted() {
    this.get_captcha_id();
    this.check_form();
    var elements = document.getElementsByTagName("INPUT");
    for (var i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function (e) {
        e.target.setCustomValidity("");
        if (!e.target.validity.valid) {
          e.target.setCustomValidity(e.target.dataset.validationText);
        }
      };
      elements[i].oninput = function (e) {
        e.target.setCustomValidity("");
      };
    }
  },
  watch: {
    'form.first_name': function () {
      this.check_form();
    },
    'form.last_name': function () {
      this.check_form();
    },
    'form.mail': function () {
      this.check_form();
    },
    'form.birthdate': function () {
      this.check_form();
    },
    'form.street': function () {
      this.check_form();
      this.get_plz();
    },
    'form.street_number': function () {
      this.check_form();
      this.get_plz();
    },
    'form.zipcode': function () {
      this.check_form();
    },
    'form.phone_number': function () {
      this.check_form();
    },
    'form.agreement': function () {
      this.check_form();
    },
    'form.project': function () {
      this.check_form();
    },
    'form.url': function () {
      this.check_form();
    },
  },
  computed: {
      mailState() {
        if (this.form.mail.length == 0) {
          return null
        }
        var letters = this.form.mail.replace(/[^a-zA-Z]/gi, "")
        if (letters.length < 3) {
          return false
        }
        return this.form.mail.length >= 3 ? true : false
      }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      axios
        .post("/api/register", JSON.stringify(this.form))
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.msg_type = "success";
          this.msg_text =
            "Daten erfolgreich übermittelt. Die Angaben werden nun geprüft und Ihnen wird ein Brief mit ihren Zugangsdaten an die oben genannte Adresse versandt.";
          this.msg_show = true;
          this.form_disabled = true;
          this.send_disabled = true;
          this.form_send = true;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          if (error != null) {
            this.msg_type = "warning";
            this.msg_show = true;
            this.msg_text = error.response.data;
            this.get_captcha_id();
            this.form.captcha_solution = "";
          }
          //console.log(error);
        });
    },
    update_streets() {
      if (this.form.street.length < 3) return;

      if (this.form.street.length == 3) {
        var prefix = this.form.street.substring(0, 3);
        if (this.street_prefix != prefix) {
          this.street_prefix = prefix;
          axios
            .get("/api/streets/" + this.form.street)
            .then((response) => (this.options.streets = response.data));
        }
      }
      if (this.form.street.length > 3) {
        axios
          .get("/api/street_numbers/" + this.form.street)
          .then((response) => (this.options.street_numbers = response.data));
      }
    },
    get_captcha_id() {
      axios
        .get("/api/captcha")
        .then((response) => (this.form.captcha_id = response.data));
    },
    get_image_captcha_url() {
      return "/api/captcha/" + this.form.captcha_id + ".png";
    },
    get_audio_captcha_url() {
      return "/api/captcha/" + this.form.captcha_id + ".wav";
    },
    suggest_mail() {
      if (
        this.form.mail == "" &&
        this.form.first_name != "" &&
        this.form.last_name != ""
      ) {
        this.form.mail =
          (this.form.first_name + "." + this.form.last_name).toLowerCase();
      }
    },
    get_plz() {
      axios
        .get("/api/plz/" + this.form.street + "/" + this.form.street_number )
        .then( (response) => {
          if (this.form.street_number != "" && this.form.street != "") {
            this.form.zipcode = response.data + " Münster";
          }
        })
    },
    check_form() {
      if (this.form_send) {
        return;
      }
      var text = "Bitte ergänzen Sie folgende Angaben: "
      var forms = [];
      if(this.form.first_name == "") {
        forms.push("Vorname");
      }
      if(this.form.last_name == "") {
        forms.push("Nachname")
      }
      if(this.form.mail == "" || !this.mailState) {
        forms.push("Gewünschte E-Mail-Adresse")
      }
      if(this.form.birthdate == "") {
        forms.push("Geburstdatum")
      }
      if(this.form.street == "") {
        forms.push("Straße")
      }
      if(this.form.street_number == "") {
        forms.push("Hausnummer")
      }
      if(this.form.phone_number == "") {
        forms.push("Telefonnummer")
      }
      if(!this.form.agreement) {
        forms.push("Nutzungsbedingungen")
      }
      if(this.form.project != "" && this.form.url == "") {
        forms.push("Nachweis")
      }

      if (forms.length > 0) {
        this.msg_show = true;
        this.msg_type = "info";
        text += forms.join(", ")
        this.msg_text = text;
        this.send_disabled = true;
      } else {
        this.send_disabled = false;
        this.msg_show = false;
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#password-hint, #send-hint {
  font-weight: bold;
  text-align: left;
}

h3 {
  margin: 40px 0 0;
}
a {
  color: #005b79;
  text-decoration: underline;
}
.form {
  margin: 40px;
}
.info p {
  text-align: left;
}

.modal-dialog {
  max-width: 80%;
}

.btn-primary {
  background-color: #005b79;
  border-color: #005b79;
}

h4 {
  font-size: 1.1rem;
}

.register {
  padding-bottom: 50px;
  margin-bottom: 50px;
}

#mail-info {
  color: red !important;
  font-size: 1em;
}
</style>
